export default {
    nav: {
        planning: {
            label: 'Operatie',
            operations: 'Operatie',
            forwarding: 'Chartering',
            archive: 'Archief'
        }
    },
    forwarding: {
        filters: {
            tabs: {
                toSell: 'Nog te verkoop',
                waitingLoad: 'Verkocht',
                waitingPod: 'Wachten op POD',
            }
        },
        header: {
            toSell: 'Nog te verkoop',
            waitingLoad: 'Verkocht',
            waitingPod: 'Wachten op POD',
            finished: 'Finished',
            customer: 'Klant',
            charter: 'Charter',
            load: 'Laad',
            unload: 'Los',
            totalKm: 'Km',
            prices: 'Prijs',
            margin: 'Marge',
        },
    },
    header: {
        archive: 'Archief',
        plannedTrips: 'Operatie',
        openTrips: 'Ongeplande routes',
        truck: 'Truck',
        fuel: 'Brandstof',
        customer: 'Klant',
        trailer: 'Trailer',
        startingActivity: 'Eerste en laatste activiteit van trip',
        totalKm: 'Totaal km',
        collapse: 'Laatste activiteit',
        hide: 'Verberg',
        comment: 'Opmerking',
        driver: 'Chauffeur',
        chat: 'Chat'
    },
    noItems: ' Geen items gevonden',
    activity: {
        overview: {
            title: 'Archief',
            proofOfDelivery: 'POD'
        },
        saveButton: 'Opslaan',
        saveAndCalculateButton: 'Opslaan & Herberekenen',
        saveAndFinalizeButton: 'Klaar voor facturatie',
        saveAndSendButton: 'Opslaan en verzenden naar chat',
        skipValidation: 'Validatie overslaan',
        buttons: {
            edit: 'Activiteit bewerken',
            delete: 'Activiteit verwijderen',
            unplanTrip: {
                label: 'Route planning annuleren',
                disableLabel: 'Activiteit moet een reis toegewezen krijgen om een reis te kunnen losplannen'
            },
            replanTrip: 'Route verplaatsen',
            finalize: 'Activiteit afronden',
            create: 'Activiteit toevoegen',
            removePriority: 'Prioriteit verwijderen',
            addPriority: 'Prioriteit',
            moveTrip: 'Verplaats route',
            sendToClient: 'Verstuur update naar klant',
            sendToDriver: 'Verstuur naar:',
            sendToDriverOption: 'of',
            sendToTransicsHint: 'Verstuur activiteit naar Transics via chat',
            sendToDriverAppHint: 'Verstuur de gehele planning naar de Driver App',
        },
        changeStatusModal: {
            header: 'Activiteitsstatus wijzigen',
        },
        statuses: {
            draft: 'Draft',
            planned: 'Gepland',
            eta: 'Rijdend',
            arrived: 'Gearriveerd',
            driving: 'Rijdend',
            working: 'Werkend',
            waiting: 'Wachtend',
            started: 'Gestart',
            finished: 'Voltooid',
            finalized: 'Afgerond',
            executing: 'In uitvoering',
            canceled: 'Geannuleerd',
            new: 'Nieuw'
        },
        priority: {
            checkbox: {
                label: 'Prioriteit'
            }
        },
        times: {
            label: 'Laden/Lossen datum'
        },
        field: {
            activity: {
                label: 'Activiteit'
            },
            allocatedTruck: {
                label: 'Toegewezen truck'
            },
            allocatedTrailer: {
                label: 'Toegewezen trailer'
            },
            allocatedDriver: {
                label: 'Toegewezen driver',
                notMatchMessage: 'Toegewezen chauffeur {{name}} is anders dan degene van transics {{transics}}',
                onlyTransicsMessage: 'Geen toegewezen chauffeur, tacho informatie van transics chauffeur {{transics}}'
            },
            id: {
                label: 'ID'
            },
            distanceTo: {
                label: 'Afstand tot'
            },
            bookingReference: {
                label: 'Boekingsreferentie'
            },
            unitLicensePlate: {
                label: 'Kenteken'
            },
            allocatedCustomerGroup: {
                label: 'Toegewezen klantgroep'
            },
            cargoSpecification: {
                label: 'Ladingspecificatie'
            },
            unumber: {
                label: 'Un-nr.'
            },
            proofOfDelivery: {
                label: 'POD'
            },
            class19: {
                label: 'Klasse (1-9)'
            },
            packgroup: {
                label: 'Packgroep (1-3)'
            },
            trip: {
                label: 'Dossier route'
            },
            lq: {
                label: 'LQ'
            },
            mrnNumber: {
                label: 'Mrn nr.'
            },
            additionalInformation: {
                label: 'Extra informatie'
            },
            type: {
                label: 'Activiteit type'
            },
            goodsDescription: {
                label: 'Beschrijving'
            },
            orderedArrivalDatetimeFrom: {
                label: 'Van'
            },
            orderedArrivalDatetimeUntil: {
                label: 'Tot'
            },
            instructions: {
                label: 'Driver instructies'
            },
            location: {
                label: 'Locatie'
            },
            reference: {
                label: 'Activiteit referentie'
            },
            remarks: {
                label: 'Opmerking'
            },
            status: {
                label: 'Status'
            },
            startKm: {
                label: 'KM begin'
            },
            endKm: {
                label: 'KM eind'
            },
            plannedKm: {
                label: 'Geplande km'
            },
            drivenKm: {
                label: 'Gereden km'
            },
            kmDeviation: {
                label: 'Km deviatie'
            },
            kmDeviationPercent: {
                label: 'Km deviatie percentage'
            },
            kmDeviationRemarks: {
                label: 'Km deviatie opmerkingen'
            },
            statusDraftAt: { label: 'Draft om', },
            statusPlannedAt: { label: 'Geplanned om', },
            statusEtaAt: { label: 'Vertrokken om', },
            statusArrivedAt: { label: 'Gearriveerd om', },
            statusDrivingAt: { label: 'Rijdend om', },
            statusWaitingAt: { label: 'Wachtende om', },
            statusStartedAt: { label: 'Gestart om', },
            statusFinishedAt: { label: 'Voltooid om', abbreviation: 'Volt.' },
            statusFinalizedAt: { label: 'Afgerond om', },
            statusCanceledAt: { label: 'Geannuleerd om', },
            dossier: {
                link: 'Open dossier'
            },
            companyName: {
                label: 'Bedrijfsnaam',
                placeholder: 'Je moet dossierreis selecteren om adres met bedrijfsnaam op te slaan'
            },
            uploadPOD: {
                label: 'Uploaden POD'
            },
            finalize: {
                label: 'Klaargezet voor facturatie om'
            },
            undoFinish: {
                label: 'Voltooiing ongedaan maken'
            }
        },
        modal: {
            title: {
                label: 'Activiteit afronden'
            },
            notification: {
                missingStatusFinalizedAt: 'Activiteit afgerond om'
            }
        },
        dossierLink: {
            popupContent: 'Selecteer om dossier {{id}} te openen'
        }
    },
    row: {
        comments: 'Voeg opmerkingen toe...',
        updated: 'Rij is onlangs geüpdate',
        popup: {
            customerGroup: {
                allocated: 'Klantgroep van huidige activiteit ({{current}}) komt niet overeen met de gealloceerde klantgroep ({{allocated}})'
            },
            hiddenActivities: '{{number}} activiteiten tussenin'
        }

    },
    expandRow: {
        content: {
            trip: 'Route',
            tripNumber: 'Route nr.',
            invoiceReference: 'Referentie',
            location: 'Locatie',
            city: 'Stad',
            gpsCoordinates: 'GPS coordinaten',
            datesAndTimes: 'Data en tijden',
            plannedArrivalTime: 'Geplande start',
            plannedStart: 'Geplande start',
            plannedEnd: 'Gepland einde',
            estimated: 'Geschatte (ETA)',
            instructions: 'Driver instructies',
            remarks: 'Opmerking',
            odometer: 'Kilometerteller',
            addDriver: 'Driver toevoegen',
            addTrailer: 'Trailer toevoegen',
            editDriver: 'Driver',
            firstDriver: 'Eerste driver: {{driver}}',
            tachoConnectionNotConfigured: 'Tacho connectie niet geconfigureerd',
            noTachoInformationAvailable: 'Geen tacho informatie beschikbaar',
            secondDriver: 'Tweede driver: {{driver}}',
            transicsDriver: 'Transics driver: {{driver}}',
            editFirstDriver: 'Eerste driver',
            editSecondDriver: 'Tweede driver',
            editTrailer: 'Trailer',
            editTruck: 'Truck',
            nextService: 'Volgende service',
            emailHaulier: 'E-mail vervoerder',
            assetAllocation: 'Assets toewijzing',
            dossier: 'Dossier',
            customer: 'Klant',
            customerGroup: 'Klantgroep',
            allocationPlaceholder: 'Onveranderd',
            allocationPlaceholderDriver: 'Geen driver toegewezen',
            showAllAllocation: 'Toon alle assets',
            tripRemarks: 'Route opmerkingen',
            dossierRemarks: 'Interne dossier opmerkingen',
            reference: 'Activiteit referentie',
            truckGpsLocation: 'Vrachtwagen GPS locatie',
            mobilePhoneGpsLocation: 'Mobile telefoon GPS locatie',
            lastGpsUpdateTime: 'Laatst geüpdatet',
        },
        contact: {
            title: 'Contact'
        },
        sendToYard: {
            title: 'Stuur naar Werf'
        },
        sendToDriver: {
            title: 'Verstuur naar Driver'
        },
        sendTerminal: {
            title: 'Verstuur Terminal'
        },
        editTrip: {
            title: 'Route bewerken',
            sendActivities: 'Verstuur activiteiten',
            activitiesSended: 'Activiteiten zijn succesvol verzonden',
            error: {
                selectActivity: 'Selecteer minimaal één activiteity',
                setTruckMail: 'Truck heeft geen e-mailadres',
            },
            activitySended: 'Activiteit is verzonden',
            activityAccepted: 'Activiteit is geaccepteerd',
            activityRejected: 'Activiteit is afgewezen',
        },
        archive: {
            title: 'Activiteitenarchief',
            finalize: {
                label: 'Controleer POD'
            },
            undoFinish: {
                label: 'Voltooiing ongedaan maken'
            }
        },
        trip: {
            title: 'Route'
        },
        activity: {
            modal: {
                sendStatusEmail: {
                    header: 'Status-e-mail verzenden',
                    trigger: 'Status-e-mail verzenden'
                },
            },
            updateStatus: 'Update Status',

            buttons: {
                sendInstructions: 'Stuur instructies',
                draft: 'Wijzig naar Concept',
                planned: 'Plan activiteit',
                eta: 'Begin met rijden',
                arrived: 'Driver gearriveerd',
                waiting: 'Driver wachtend',
                started: 'Activiteit gestart',
                finished: 'Activiteit voltooid',
                finalized: 'Activiteit afgerond',
                canceled: 'Activiteit geannuleerd',
                changeLocationActivity: 'Locatieactiviteit wijzigen',
                changeDateActivity: 'Activiteitsdatum wijzigen',
            },
            addButton: 'Activiteit',
            deleteActivityButton: 'Activiteit verwijderen',
            new: 'Nieuw',
            information: 'Informatie',
            details: 'Details',
            cancelTrip:{
                popup: 'Trip bevat activiteiten die worden uitgevoerd/afgerond of aan de reis is geen dossier toegewezen.',
                notification: 'Trip succesvol geannuleerd'
            },
            uncancelTrip:{
                popup: 'Trip bevat activiteiten die worden uitgevoerd/afgerond of aan de reis is geen dossier toegewezen.',
                notification: 'Trip succesvol heractiveren'
            }
        },
        truck: {
            noTruck: 'Momenteel is er geen truck gekoppeld',
            noChat: 'Momenteel is er geen chat beschikbaar',
            generalInformation: 'Truck informatie',
            activitiesTruck: {
                header: 'Activiteiten truck',
                trip: 'Route',
                activity: 'Activiteit',
                trailer: 'Trailer',
                location: 'Locatie',
                status: 'Status',

            }
        },
        trailer: {
            noTrailer: 'Momenteel is er geen trailer gekoppeld',
            generalInformation: 'Trailer informatie',
            performanceInformation: 'Prestatie',
            activitiesTrailer: {
                header: 'Trailer route',
                trip: 'Route',
                truck: 'Truck',
                unloadingCity: 'Los locatie',
                loadingCity: 'Laad locatie',
                tripDistance: 'Afstand (km)',

            }

        },
    },
    truck: {
        field: {
            customerAllocation: {
                label: 'Klant toewijzing'
            }
        }
    },
    trip: {
        label: 'Route',
        charterTrip: 'Charter trip',
        undoCharterTrip: 'Undo charter trip',
        field: {
            remarks: {
                label: 'Route opmerkingen'
            }
        }
    },
    dossier: {
        field: {
            remarks: {
                label: 'Interne dossier opmerkingen'
            },
            invoiceReference: {
                label: 'Referentie'
            },
        }
    },
    tachoEvent: {
        assistantDrivingTimes: 'Rijtijden',
        total: 'Totaal',
        maximum: 'Maximum',
        minimum: 'Minimum',
        remaining: 'Over',
        ID: 'ID:',
        transicsID: 'TransicsID:',
        code: 'Code:',
        description: 'Beschrijving:',
        started: 'Gestart:',
        drivingTimeBelowMaximum: 'Driving Time Below Maximum',
        dailyDrivingTime: 'Totale rijtijd (dag)',
        daysOfDrivingBiggerThan9h: 'Dagen langer dan 9u gereden',
        weeklyDrivingTime: 'Totale rijtijd (week)',
        dailyAmplitude: 'Duur werkdag',
        workWeek: 'Duur werkweek',
        assistantRestPeriods: 'Rust periodes',
        breakBelowMinimum: 'Break Below Minimum',
        dailyRest: 'Dagelijkse rust (vandaag)',
        previousDailyRest: 'Dagelijkse rust (gisteren)',
        compensableWeeklyRest: 'Weekend pause',
        weeklyRestMinus1: 'Weekly Rest Minus1',
        weeklyRestMinus2: 'Weekly Rest Minus2',
        weeklyRestMinus3: 'Weekly Rest Minus3',
    },
    filters: {
        tab: {
            planning: 'Operatie',
            trips: 'Ongeplande routes'
        },
        fleetNumber: {
            label: 'Truck vlootnummer',
            placeholder: 'Zoek op vlootnummer ...',
        },
        licensePlate: {
            label: 'Truck kenteken',
            placeholder: 'Zoek op kenteken ...',
        },
        customerAllocation: {
            label: 'Klant',
            placeholder: 'Zoek op klant ...',
        },
        customer: {
            label: 'Klant',
            placeholder: 'Zoek op klant ...',
        },
        activityLocation: {
            label: 'Activiteit locatie',
            placeholder: 'Zoek op locatie ...',
        },
        districts: {
            label: 'Districten',
            placeholder: 'Selecteer district',
        },
        date: {
            label: 'Datum',
            placeholderStart: 'Start datum',
            placeholderEnd: 'Eind datum',
        },
        clearButton: {
            label: 'Filters wissen'
        },
        section: {
            label: 'Sectie'
        },
        planning: {
            label: 'Operatie'
        },
        status: {
            label: 'Route status',
        },
        openTrips: {
            label: 'Ongeplande routes'
        },
        collapsed: {
            label: 'Laatste activiteit',
        },
        showFinished: {
            label: 'Toon voltooide activiteiten',
        },
        showFinalized: {
            label: 'Toon activiteit die klaar zijn voor facturatie',
        },
        hideColumns: {
            label: 'Verberg kolommen',
        },
        addLine: {
            label: 'Lijn toevoegen',
        },
        removeLine: {
            label: 'Lijn verwijderen',
        },
        inactive: {
            label: 'Actief / Inactief',
            all: 'Alles',
            active: 'Actief ',
            inactive: 'Inactief',
        },
        assignedTo: {
            label: 'Toegewezen aan',
        },
        owner: {
            label: 'Eigenaar',
        },
        customerGroup: {
            label: 'Klantgroep',
            placeholder: 'Klantgroep',
        },
        currentCustomerGroup: {
            label: 'Huidige Klantgroep',
            placeholder: 'Klantgroep',
        },
        futureCustomerGroup: {
            label: 'Toekomstige Klantgroep',
            placeholder: 'Klantgroep',
        },
        all: {
            label: 'Alles',
        },
        waiting: {
            label: 'Wachtend',
        },
        busy: {
            label: 'Bezig',
        },
        limit: {
            label: 'Limiet',
        },
        autoRegenerate: {
            label: 'Elke 5 minuten regenereert het verkoopplan'
        },
        flaggedRows: {
            label: 'Gemarkeerde rijen',
            all: 'Alles',
            flagged: 'Gemarkeerd'
        },
        weekendPause: {
            label: 'Weekend pause'
        },
        dossierNumber: {
            label: 'Dossier nr.',
            placeholder: 'Dossier nr.',
        },
        driver:{
            label: 'Chauffeur',
            placeholder: 'Chauffeur',
        }
    },
    modalMoveTrip: {
        header: 'Verplaats route {{id}} naar geselecteerde truck',
        buttons: {
            move: 'Verplaats route'
        }
    },
    modalAllocateCustomer: {
        header: 'Wijs klantgroep toe aan geselecteerde vrachtwagens',
        field: {
            customerGroup: {
                label: 'Klantgroep'
            }
        },
        buttons: {
            allocate: 'Toewijzen'
        },
        required: {
            customerGroup: 'Klantgroep is vereist'
        }
    },
    modalCreateTrailer: {
        header: 'Nieuwe trailer aanmaken en toewijzen',
    },
    modalCreateTruck: {
        header: 'Nieuwe truck aanmaken en toewijzen',
    },
    modalCreateDriver: {
        header: 'Nieuwe driver aanmaken en toewijzen',
    },
    modalSwap: {
        noActivities: 'Geen activiteiten',
        swapLocationLabel: 'Wissellocatie',
        secondTruck: 'Truck 2',
        description: 'Door een activiteit te selecteren, wijs je een reeks activiteiten aan (inclusief de geselecteerde activiteit) die tussen vrachtwagens worden gewisseld.\nAlleen de geselecteerde set activiteiten wordt verplaatst van de ene naar de andere truck.',
        header: 'Modaal wisselen',
        swapLocation: {
            description: 'De plaats en tijd van de uitwisseling tussen de twee vrachtwagens, die wordt gepland'
        },
        error: {
            header: 'Actie Verboden',
            message: 'Activiteit kan niet worden verplaatst omdat deze niet kan worden geplaatst vóór de laatste activiteit in de tegenovergestelde set activiteiten, die niet wordt verwisseld.'
        },
        resultText: 'Resultaat'
    },
    tripSplitModal: {
        header: 'Trip split modal',
        description: 'Hierdoor worden alle activiteiten na de trailer drop activiteit verplaatst naar een nieuwe rit, voorafgegaan door een trailer pick up op de locatie van de trailer drop.\nWeet je zeker dat je door wilt gaan?',
    },
    toolbar: {
        buttons: {
            showActivityArchive: 'Toon archief',
            groupBy: {
                label: 'Groeperen on',
                options: {
                    NO_GROUPING: 'Geen groepering',
                    CUSTOMER_GROUP: 'Klantgroep'
                }
            },
            swap: {
                label: 'Vervangende trailer',
                warning: 'Je moet 2 trucks selecteren om te beginnen met het ruilen van activiteiten'
            },
            tripSplit:{
                label: 'Trip split'
            }
        }
    },
    form: {
        deleteConfirmationActivity: 'Weet u zeker dat u deze activiteit wilt verwijderen?',
    },
};
