import React from 'react';
import { Link, t, TargetSelect } from '@code-yellow/spider';
import AdminOverview from 'component/AdminOverview';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import ActiveButtons from 'react-logistics-masterdata/src/component/ActiveButtons';
import { TrailerStore } from '../../store/Trailer';
import { debounce } from 'lodash';
import { theme } from 'styles';
import { CustomerStore } from 'react-logistics-administration/src';


@observer
export default class TrailerOverviewScreen extends AdminOverview {
    @observable store = new TrailerStore({
        'relations': [
            'truckingCompany',
            // 'currentActivity.location', virtual relation commented out, maybe will be used later
            // 'currentActivity.trip.dossier'
        ]
    });

    params = {
        include_annotations: [
            '*',
            'last_known_location',
            'dossier_number'
        ].join(','),
    }

    fullWidth = true;
    truckingCompanyStore = new CustomerStore();

    title = t('masterData:trailer.overview.title');
    myFilterKey = 'trailer-overview';

    allowExport = true;
    highlightedRows = true;
    showRenderTypeToggle = true;
    showPaginationLimit = true;

    rowProps(item, i) {
        return { 'data-test-trailer': item.id };
    }

    renderButtonActive = (trailer) => {
        return (
            <ActiveButtons
                asset={trailer}
                renderType={this.selectedRenderType}
            />
        );
    };

    formatDate = (date) => {
        return date ? new Date(date).toLocaleDateString('ES-CL') : '';
    };


    settings = [
        {
            label: t('masterData:trailer.field.fleetNumber.label'),
            attr: ({ fleetNumber }) => fleetNumber,
            sortKey: 'fleet_number',
            cellProps: { 'data-test-fleet-number': true },
        },
        {
            label: t('masterData:trailer.field.licensePlate.label'),
            attr: ({ licensePlate }) => licensePlate,
            sortKey: 'license_plate',
            cellProps: { 'data-test-license-plate': true },
        },
        {
            attr: ({ dossierNumber }) => <Link style={{ color: theme.primaryColor }} to={`/administration/dossier/number/${dossierNumber}/edit`}>{dossierNumber ? dossierNumber : ''}</Link>,
            label: t('administration:dossier.field.dossierNumber.label'),
            sortKey: 'dossier_number',
            cellProps: { 'data-test-dossier-number': true },
        },
        {
            label: 'Last known location',
            attr: (trailer) => trailer.lastKnownLocation ? trailer.lastKnownLocation : '',
            sortKey: 'last_known_location',
            cellProps: { 'data-test-current-activity-location': true },
        },
        {
            label: t('masterData:trailer.field.apkExpiryDate.label'),
            attr: ({ apkExpiryDate }) => this.formatDate(apkExpiryDate),
            sortKey: 'apk_expiry_date',
            cellProps: { 'data-test-apk-expiry-date': true },
        },
        {
            label: t('masterData:trailer.field.cooled.overviewLabel'),
            attr: ({ cooled }) => cooled,
            cellProps: { 'data-test-cooled': true },
        },
        {
            label: t('masterData:trailer.field.lift.overviewLabel'),
            attr: ({ lift }) => lift,
            cellProps: { 'data-test-lift': true },
        },
        {
            label: t('masterData:trailer.field.taut.label'),
            attr: ({ taut }) => taut,
            cellProps: { 'data-test-taut': true },
        },
        {
            label: t('masterData:trailer.field.frigo.label'),
            attr: ({ frigo }) => frigo,
            cellProps: { 'data-test-frigo': true },
        },
        {
            label: t('masterData:trailer.field.box.label'),
            attr: ({ box }) => box,
            cellProps: { 'data-test-box': true },
        },
        {
            label: t('masterData:trailer.field.adr.label'),
            attr: ({ adr }) => adr,
            cellProps: { 'data-test-adr': true },
        },
        {
            label: t('masterData:trailer.field.truckingCompany.label'),
            attr: ({ truckingCompany }) => truckingCompany.name,
            sortKey: 'trucking_company.name',
            cellProps: { 'data-test-trucking-company': true },
        },
        {
            label: t('masterData:trailer.field.active.overviewColumn'),
            attr: this.renderButtonActive,
            sortKey: 'active',
            collapsing: true,
            cellProps: { 'data-test-active': true },
        },
        {
            collapsing: true,
        },
    ];

    buttons = [
        { type: 'edit', to: (u) => `/master-data/trailer/${u.id}/edit` },
    ];

    toolbar = [
        { type: 'add', to: '/master-data/trailer/add', label: t('masterData:trailer.overview.addButton') },
    ];

    filters = [
        {
            type: 'radioButtons',
            name: '.active',
            label: t('masterData:trailer.field.active.label'),
            options: [
                { text: t('masterData:trailer.field.active.options.all'), value: undefined },
                { text: t('masterData:trailer.field.active.options.yes'), value: 'false' },
                { text: t('masterData:trailer.field.active.options.no'), value: 'true' },
            ],
            primary: true
        },
        {
            type: 'text',
            label: t('masterData:truck.field.fleetNumber.label'),
            name: '.fleet_number:icontains',
        },
        {
            type: 'search',
            label: t('masterData:trailer.field.licensePlate.label'),
            name: '.license_plate:icontains',
        },
        {
            type: 'custom',
            callback: () => (
                <TargetSelect
                    multiple
                    remote clearable
                    store={this.truckingCompanyStore}
                    name=".trucking_company.name:icontains"
                    target={this.store}
                    label={t('masterData:trailer.field.owner.label')}
                    placeholder={t('masterData:trailer.field.owner.label')}
                    afterChange={debounce(() => this.store.fetch(), 250)}
                    options={this.truckingCompanyStore.map(item => ({
                        value: item.id,
                        text: item.name,
                    }))}
                />
            )
        },
        {
            type: 'radioButtons',
            name: '.cooled',
            label: t('masterData:trailer.field.cooled.label'),
            options: [
                { text: t('masterData:trailer.field.cooled.options.all'), value: undefined },
                { text: t('masterData:trailer.field.cooled.options.yes'), value: 'true' },
                { text: t('masterData:trailer.field.cooled.options.no'), value: 'false' },
            ],
            primary: true
        },
        {
            type: 'radioButtons',
            name: '.lift',
            label: t('masterData:trailer.field.lift.label'),
            options: [
                { text: t('masterData:trailer.field.lift.options.all'), value: undefined },
                { text: t('masterData:trailer.field.lift.options.yes'), value: 'true' },
                { text: t('masterData:trailer.field.lift.options.no'), value: 'false' },
            ],
            primary: true
        },
        {
            type: 'radioButtons',
            name: '.taut',
            label: t('masterData:trailer.field.taut.label'),
            options: [
                { text: t('masterData:trailer.field.taut.options.all'), value: undefined },
                { text: t('masterData:trailer.field.taut.options.yes'), value: 'true' },
                { text: t('masterData:trailer.field.taut.options.no'), value: 'false' },
            ],
            primary: true
        },
        {
            type: 'radioButtons',
            name: '.frigo',
            label: t('masterData:trailer.field.frigo.label'),
            options: [
                { text: t('masterData:trailer.field.frigo.options.all'), value: undefined },
                { text: t('masterData:trailer.field.frigo.options.yes'), value: 'true' },
                { text: t('masterData:trailer.field.frigo.options.no'), value: 'false' },
            ],
            primary: true
        },
        {
            type: 'radioButtons',
            name: '.box',
            label: t('masterData:trailer.field.box.label'),
            options: [
                { text: t('masterData:trailer.field.box.options.all'), value: undefined },
                { text: t('masterData:trailer.field.box.options.yes'), value: 'true' },
                { text: t('masterData:trailer.field.box.options.no'), value: 'false' },
            ],
            primary: true
        },
        {
            type: 'radioButtons',
            name: '.adr',
            label: t('masterData:trailer.field.adr.label'),
            options: [
                { text: t('masterData:trailer.field.adr.options.all'), value: undefined },
                { text: t('masterData:trailer.field.adr.options.yes'), value: 'true' },
                { text: t('masterData:trailer.field.adr.options.no'), value: 'false' },
            ],
            primary: true
        },
        {
            type: 'text',
            label: t('administration:activity.field.location.label'),
            name: '.location_filter:icontains',
        }
    ];
}
