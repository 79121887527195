import { t, SaveButton, showErrorNotification } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import AdminEditScreen from 'component/AdminEditScreen'
import { Subheading } from 're-cy-cle';
import React from 'react';
import { Message } from 'semantic-ui-react';
import { Trailer } from '../../store/Trailer';
import ActiveButtons from 'react-logistics-masterdata/src/component/ActiveButtons';
import { formatInputToUppercase, validateLicensePlateInput } from 'react-core-administration/src/helpers';
import { CustomerStore } from 'react-logistics-administration/src';

type TrailerEditProps = {
    truck: Trailer,
    createNewTruck: () => void,
    afterSave: () => void,
    isModal?: boolean
}

@observer
export class TrailerEdit extends AdminEditScreen{
    static baseUrl = '/master-data/trailer/';
    props: TrailerEditProps;

    Model = Trailer;
    relations = ['truckingCompany'];

    isModal = this.props?.isModal ?? false;
    truckingCompanyStore = new CustomerStore();


    componentDidMount() {
        super.componentDidMount();
        this.model = this.props.trailer;
    }

    renderTitle() {
        return this.model && this.model.id
            ? t('masterData:trailer.edit.title', { id: this.model.fleetNumber })
            : t('masterData:trailer.create.title');
    }

    renderInactiveMessage(trailer: Trailer) {
        return trailer && trailer.inactive && trailer.id ? (
            <Message negative>
                <Message.Header>{t('masterData:trailer.edit.trailerDeactivated')}</Message.Header>
            </Message>
        ) : null;
    }

    renderButtonActive = (trailer: Trailer) => (
        <ActiveButtons
            asset={trailer}
            renderType='standard'
            label={t('masterData:trailer.field.active.label')}
        />
    )

    save = ({ createNew = false, saveAndBack = false }) => {
        const { afterSave } = this.props;
        return this.model
            .save({
                onlyChanges: true,
                relations:  ['truckingCompany'],
            })
            .then(() => {
                const afterSaveProps = {
                    goBack: false
                };
                if (saveAndBack){
                    afterSaveProps.goTo = '/master-data/trailer/overview';
                    afterSaveProps.goBack = saveAndBack;
                }
                if(createNew){
                    afterSaveProps.goTo = '/master-data/trailer/add';
                    afterSaveProps.goBack = createNew;
                    this.model.clear();
                }
                afterSave(afterSaveProps)
            })
            .catch((err) => {
                const errorCode = err.response?.data?.errors?.trailer?.fleet_number?.[0]?.code;
                if (errorCode === 'existing_fleet_number') {
                    showErrorNotification(t('masterData:trailer.edit.errorMessage.fleetNumber'));
                } else {
                    // Handle other types of errors if needed
                }
            });
    };

    renderRightButtons() {
        return <>
                <SaveButton primary compact
                    onClick={() => this.save({ saveAndBack: true })}
                    content={t('masterData:form.saveAndBack')}
                    loading={this.model.isLoading}
                />
                <SaveButton primary compact
                    onClick={() => this.save({ createNew: true })}
                    content={t('masterData:form.saveCreateEmpty')}
                    loading={this.model.isLoading}
                />
                <SaveButton
                    primary
                    compact
                    data-test-save-same-trailer-button
                    loading={this.model.isLoading}
                    onClick={this.save}
                />
        </>
    }

    fields = [
        {
            type: 'custom',
            callback: this.renderInactiveMessage,
        },
        {
            type: 'custom',
            callback: () => (
                <Subheading>{t('masterData:trailer.edit.basicInfoTitle')}</Subheading>
            ),
        },
        (trailer) => ({ type: 'group', widths: 'equal', fields: [
            {
                type: 'text',
                name: 'fleetNumber',
                label: t('masterData:trailer.field.fleetNumber.label'),
                required: true,
            },
            {
                type: 'text',
                name: 'licensePlate',
                label: t('masterData:trailer.field.licensePlate.label'),
                required: true,
                onKeyPress: validateLicensePlateInput,
                onBlur: (event: Event) => formatInputToUppercase(event, trailer.licensePlate, trailer, 'licensePlate')
            },
        ] }),
        {
            type: 'select',
            name: 'truckingCompany',
            label: t('masterData:trailer.field.truckingCompany.label'),
            store: this.truckingCompanyStore,
            remote: true,
            clearable: true,
            searchKey: '.name:icontains',
            toOption: truckingCompany => ({
                value: truckingCompany.id,
                text: truckingCompany.name,
            }),
            props: {
                'data-test-owner': 'true',
            },
        },
        {
            type: 'date',
            name: 'apkExpiryDate',
            label: t('masterData:trailer.field.apkExpiryDate.label'),
        },
        { type: 'group', widths: '10', fields: [
            {
                type: 'bool',
                name: 'cooled',
                label: t('masterData:trailer.field.cooled.overviewLabel'),
            },
            {
                type: 'bool',
                name: 'lift',
                label: t('masterData:trailer.field.lift.overviewLabel'),
            },
            {
                type: 'bool',
                name: 'taut',
                label: t('masterData:trailer.field.taut.label'),
            },
            {
                type: 'bool',
                name: 'frigo',
                label: t('masterData:trailer.field.frigo.label'),
            },
            {
                type: 'bool',
                name: 'box',
                label: t('masterData:trailer.field.box.label'),
            },
            {
                type: 'bool',
                name: 'adr',
                label: t('masterData:trailer.field.adr.label'),
            },
        ] },
        {
            type: 'custom',
            callback: () => (
                <Subheading>{t('masterData:trailer.edit.trailerSpecTitle')}</Subheading>
            ),
        },
        {
            type: 'textArea',
            name: 'remarks',
            label: t('masterData:trailer.field.remarks.label'),
        },
        {
            type: 'custom',
            callback: this.renderButtonActive,
        },
    ]
}
